import { CssAwareElement } from "tds_element";
import { css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
@customElement("tds-contact-map")
export class ContactMap extends CssAwareElement {
  static styles = css`
    * {
      box-sizing: border-box;
    }

    :host {
      display: block;
      position: relative;
    }

    *[hidden] {
      display: none;
    }

    #map {
      border: 1px solid lightgray;
      width: 100%;
    }

    .overlay {
      cursor: pointer;
      background: transparent;
      position: absolute;
      width: 100%;
      height: 300px;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }

    .address {
      font-size: 14px;
      text-align: left;
      line-height: 1.8em;
    }
  `;

  constructor() {
    super();
    this.clicked = false;
  }

  createRenderRoot() {
    /**
     * Render template without shadow DOM. Note that shadow DOM features like
     * encapsulated CSS and slots are unavailable.
     */
    return this;
  }

  @property({ type: Boolean })
  clicked: boolean;

  render() {
    return html`
      <div
        class="overlay"
        ?hidden="${this.clicked}"
        @click="${() => (this.clicked = true)}"
      ></div>
      <iframe
        id="map"
        height="300"
        frameborder="0"
        scrollwheel="no"
        marginheight="0"
        marginwidth="0"
        src="https://www.openstreetmap.org/export/embed.html?bbox=5.088622570037843%2C52.05980659634847%2C5.097634792327881%2C52.06417326755412&amp;layer=mapnik&amp;marker=52.06198998531515%2C5.093128681182861"
      ></iframe>
      <div class="address">
        <a href="https://goo.gl/maps/9pthvJpBGMQ2" target="_blank">
          <strong>Bezoekadres:</strong><br />
          Papendorpseweg 95<br />
          3528 BJ Utrecht
        </a>
      </div>
    `;
  }
}
