import { Message } from "stateful-messaging-system";
export class UrlChanged extends Message {

	location: string;
	parts: string[]
	page: string;
	lang: string;
	hash: string;
	constructor(location: Location) {
		super();
		this.location = location.pathname;
		this.hash = location.hash;

		let p = this.parseUrl(this.location);
		this.parts = p.parts;
		this.lang = p.lang;
		this.page = p.page;
		window.scrollTo(0, 0);

	}

	private parseUrl(url: string) {
		let parts = url.split('/').filter((el) => { return el.length != 0 });
		let res = { parts, lang: "nl", page: null };
		if (parts.length == 0) {
			res.page = "home";
		}
		else if (parts.length == 1) {
			res.page = parts[0];
		}
		else if (parts.length >= 2) {
			res.page = parts[0];
		}
		return res;
	}

	isActivePage(url: string): boolean {
		let p = this.parseUrl(url)
		return this.page == p.page && this.lang == p.lang;
	}
}


export class UrlHashChanged extends Message {
	hash: string;
	constructor(l: Location) {
		super();
		this.hash = l.hash;
		history.replaceState("", document.title, l.pathname);

	}
}