import { css } from "lit";

export const defaultCss = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin: 0 0 20px;
    color: #333;
    line-height: 1.4;
  }

  h2 {
    line-height: 50px;
  }
  h3 {
    font-size: 35px !important;
    line-height: 40px !important;
  }
  h4 {
    font-size: 30px !important;
    line-height: 35px !important;
  }

  *::selection {
    color: #fff;
    background: #333;
    text-shadow: none;
  }
  p {
    margin: 0 0 20px;
    box-sizing: border-box;
    color: #282828;
  }
  a,
  a:visited {
    text-decoration: none;
    display: inline;
    margin: 0;
    color: inherit;
  }

  p.light {
    font-weight: 300;
  }

  tds-light-text,
  tds-lighter-text {
    display: inline-block;
    margin: 0 0 20px;
    margin-block-end: 20px;
    box-sizing: border-box;
    color: #282828;
    font-weight: 300;
  }

  tds-lighter-text {
    font-size: 14px;
  }

  tds-bold-text {
    display: inline-block;
    font-weight: 600;
    margin: 0 0 20px;
    margin-block-end: 20px;
    color: #333;
    line-height: 1.4;
  }
  tds-bold-text[justify] {
    text-align: justify;
  }
  tds-bold-text[start] {
    text-align: start;
  }

  tds-white-bold-text {
    display: inline-block;
    margin-block-end: 20px;
    font-weight: 600;
    color: white;
    letter-spacing: 0.5px;
    padding-bottom: 10px !important;
  }

  tds-red-bold-text {
    display: inline-block;
    margin-block-end: 20px;
    font-weight: 600;
    color: #f10252;
    letter-spacing: 0.5px;
  }

  tds-header-title {
    font-size: 60px;
    line-height: 1;
    text-align: center;
    padding-bottom: 8px;
  }

  a[underline] {
    text-decoration: underline;
  }

  /* from pages: */

  .section-content {
    width: 555px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  tds-separator-gradient {
    background-color: transparent;
    background-image: linear-gradient(240deg, #ff4a00 0%, #f10252 100%);
    width: 100vw;
    height: 40px;
    display: block;
  }

  @media only screen and (max-width: 768px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 0 10px !important;
      line-height: 1.2 !important;
    }

    h2 {
      font-size: 40px !important;
      line-height: 40px !important;
      margin-bottom: 20px !important;
      margin-top: 20px !important;
    }
    h3 {
      font-size: 35px !important;
      line-height: 40px !important;
      margin-bottom: 10px !important;
      margin-top: 10px !important;
    }
    tds-bold-text {
      line-height: 1.2 !important;
      padding-left: 10px;
      padding-right: 10px;
    }

    tds-header-title {
      font-size: 44px;
      padding-bottom: 6px;
    }

    tds-columns-block {
      padding: 10px !important;
    }
    tds-columns-block > * {
      width: 100% !important;
    }
  }
`;
